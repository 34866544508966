<template>
	<div class="page-sign-up">
		<div class="columns">
			<div class="column">
				<h1 class="title">Sign up</h1>

				<form @submit.prevent="submitForm">		
					<div class="field">
						<label>First Name</label>
						<div class="control">
							<input type="text" class="input" v-model="first_name">
						</div>						
					</div>

					<div class="field">
						<label>Last Name</label>
						<div class="control">
							<input type="text" class="input" v-model="last_name">
						</div>						
					</div>					

					<div class="field">
						<label>Email</label>
						<div class="control">
							<input type="email" class="input" v-model="email">
						</div>						
					</div>

					<div class="field">
						<label>Password</label>
						<div class="control">
							<input type="password" class="input" v-model="password">
						</div>						
					</div>

					<div class="field">
						<label>Repeat Password</label>
						<div class="control">
							<input type="password" class="input" v-model="password2">
						</div>						
					</div>

					<div class="notification is-danger" v-if="errors.length">
						<p v-for="error in errors" v-bind:key="error">{{ error }}</p>						
					</div>

					<div class="field">
						<div class="control">
							<button class="button is-dark">Sign up</button>
						</div>						
					</div>

					<hr>

					Or <router-link to="/log-in">click here</router-link> to log in!
				</form>				
			</div>			
		</div>		
	</div>
</template>

<script>
import axios from 'axios'	
import { toast } from 'bulma-toast'

export default {
	name: 'SignUp',
	data() {
		return	{
			first_name: '',
			last_name: '',			
			email: '',
			password: '',
			password2: '',
			errors: []
		}
	},
	methods: {
		submitForm() {
			this.errors = [];

			if (this.first_name === ''){
				this.errors.push('The first name is missing');
			}

			if (this.last_name === ''){
				this.errors.push('The last name is missing');
			}			
			
			if (this.email === ''){
				this.errors.push('Email address is missing');
			} else if (!this.validEmail(this.email)) {
				this.errors.push('Enter a valid email address');
			}

			if (this.password === ''){
				this.errors.push('The password is too short');
			}

			if (this.password !== this.password2){
				this.errors.push('The passwords do not match');
			}

			if (!this.errors.length) {
				const formData = {
					first_name: this.first_name,					
					last_name: this.last_name,
					password: this.password,
					email: this.email
				}

				axios
					.post("/api/v1/users/", formData)
					.then(response => {
						toast({
							message: 'Account created, please log in!',
							type: 'is-success',
							dismissible: true,
							pauseOnHover: true,
							duration: 2000,
							position: 'bottom-right',
						})

						this.$router.push('/log-in')
					})
					.catch(error => {
						if (error.response) {
							for (const property in error.response.data) {
								this.errors.push(`${property}: ${error.response.data[property]}`)
							}

							console.log(JSON.stringify(error.response.data))
						} else if (error.message) {
							this.errors.push('Something went wrong. Please try again')

							console.log(JSON.stringify(error))
						}
					})
			}

		},
		validEmail(email) {
			var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return re.test(email)
		}
	}
}
</script>