<template>
    <div id="tvjs-chart" ref="tvjsChart">
        <trading-vue :data="chart"
            :chart-config="{ DEFAULT_LEN: 120 }" 
            :color-back="colors.colorBack"
            :color-grid="colors.colorGrid"
            :color-text="colors.colorText"
            :width="size.width"
            :height="size.height"
            :toolbar="true"        
            :overlays="overlays"
            :title-txt="stockName" ref="tvjs">
        </trading-vue>
    </div>
</template>

<script>
import { TradingVue, DataCube } from 'trading-vue-js'
//ToDo: Fix issue with overlays and then enable this code
//import Dividend from '@/components/Dividend/Dividend.vue'
import Buy from '@/components/Buy/Buy.vue'
import Sell from '@/components/Sell/Sell.vue'
import axios from 'axios'

export default {
    name: 'TradingVueJSChart',
    components: { TradingVue }, 
    data () {
        return {
            stockName: '',
            chart: {},
            colors: {
                colorBack:'#fff',
                colorGrid:'#eee',
                colorText:'#333'
            },
            //overlays: [Dividend,Buy,Sell],
            overlays: [Buy,Sell],
            size : {
                height:window.height,
                width:window.width
            } 
        }
    }, 
    mounted() {       
        this.getSize()
    },    
    methods: {
        getSize() {
            var width = document.getElementById('parent-tabs').clientWidth;
            this.size.width = width;
            
            var height = document.getElementById('parent-tabs').clientHeight;
            this.size.height = height/2;
        },
        onResize(event) {
            this.getSize()
        },
        getChartData(stock){
            var chart_data = {}
            chart_data["chart"] = {
                "type": "Candles",
                "data":[],
                "indexBased":true
            }
            chart_data["onchart"] = []

            var dividend = {
                "name":"Dividend",
                "type":"Dividend",
                "data":[],
                "settings": {
                    "z-index":5
                }
            }
            var splitters = {
                "name":"Data sections",
                "type":"Splitters",
                "data":[],
                "settings": {
                    "legend":false
                }
            }

            this.getStockPrices(stock, chart_data)
            
            this.getStockEvents(stock, dividend, splitters)
            chart_data["onchart"].push(dividend)        
            /*
            this.getStockNews(stock, splitters)
            chart_data["onchart"].push(splitters)         
            this.$set(this, 'chart', new DataCube(chart_data))
            */            
            this.$set(this, 'chart', chart_data)
            this.$refs.tvjs.resetChart()            
        },
        async getStockPrices(stock, chart_data) {
            this.stockName = stock.symbol
            await axios
                .get(`/api/v1/history/${stock.symbol}/`)
                .then(response => {
                    this.transformStockPrices(response.data, chart_data)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        transformStockPrices(stock, chart_data) {
            var bband = {
                /**
                 * ToDo: integrate with talib and compute BB bands
                 * timestamp, upper band, middle, lower band
                 * */
                "name":"Bolinger Band",
                "type":"BB",
                "data":[],
                "settings": {}
            } 
            var buySignals = {
                "name":"Buy",
                "type":"Buy",
                "data":[],
                "settings": {
                    "z-index":5
                }
            }
            var sellSignals = {
                "name":"Sell",
                "type":"Sell",
                "data":[],
                "settings": {
                    "z-index":5
                }
            }
            var ohlcvdata = []
            var bbanddata = []
            var buydata = []
            var selldata = []            

            for (var j=0; j<stock['prices'].length; j++) {
                var item = stock['prices'][j]
                var price = {}
                /**
                 * Get tohlcv for candles and leave bband data out.
                 */
                price['timestamp']=new Date(item['timestamp']).getTime()
                price['o'] = item['o']
                price['h'] = item['h']
                price['l'] = item['l']
                price['c'] = item['c']
                price['v'] = item['v']
                ohlcvdata.push(Object.values(price))

                var band = {}
                /**
                 * Get tohlcv for candles and leave bband data out.
                 */
                band['timestamp']=new Date(item['timestamp']).getTime()
                band['h'] = item['hband']
                band['m'] = item['mean']
                band['l'] = item['lband']

                bbanddata.push(Object.values(band))
                /**
                 * Get buy and sell signals in prices data received from backend
                 */
                var signal = {}
                signal['timestamp']=new Date(item['timestamp']).getTime()                
                if(item['buy'] != 0) {
                    signal['buy'] = item['buy']                    
                    signal['name'] = 'Buy'
                    buydata.push(Object.values(signal))
                }
                else if(item['sell'] != 0) {
                    signal['sell'] = item['sell']                    
                    signal['name'] = 'Sell' //re-using the Buy component for now.
                    selldata.push(Object.values(signal))
                }
            }
            bband['data'] = bbanddata   
            buySignals['data'] = buydata
            sellSignals['data'] = selldata
            chart_data['chart']['data'] = ohlcvdata
            //chart_data["onchart"].push(bband)              
            chart_data["onchart"].push(buySignals)
            chart_data["onchart"].push(sellSignals)            
        },        
        async getStockEvents(stock, dividend, splitters) {
            this.stockName = stock.symbol
            await axios
                .get(`/api/v1/events/${stock.symbol}/`)
                .then(response => {
                    this.transformStockEvents(response.data, dividend, splitters)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        transformStockEvents(stock, dividend, splitters) {
            var div = []
            var spl = []
            for (var j=0; j<stock["events"].length; j++) {
                var item = stock["events"][j]
                item["exdate"]=new Date(item["exdate"]).getTime()
                var evt = {}
                /** The order of presenting data is important for rendering in Dividend.vue component 
                 * timestamp, dividend, subject
                 * */
                evt["timeStamp"]=item["exdate"]               
                evt["dividend"]=item["value"]
                if(item["type"] === "Dividend") {               
                    evt["name"]=item["type"]                    
                    div.push(Object.values(evt))
                }
                else if(item["type"] === "Bonus") {
                    spl.push(Object.values(evt))
                }
            }
            dividend["data"] = div
            this.assignSplitterData(spl,splitters)
        },                
        assignSplitterData(spl,splitters) {
            /* Data for splitters are obtained from events and news of a stock. Trading-view-js requires data in a single array.
                * Ensure that data is a single array by adding each element instead of pushing one array into another.
                * */
            if (splitters["data"].length) {
                for(let i=0; i<spl.length; i++) {
                    splitters["data"].push(spl[i])
                }
            }
            else {
                splitters["data"] = spl
            }
        },
        async getStockNews(stock, splitters) {
            this.stockName = stock.symbol
            await axios
                .get(`/api/v1/news/${stock.symbol}/`)
                .then(response => {
                    this.transformStockNews(response.data, splitters)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        transformStockNews(stock, splitters) {
            var spl = []
            for (var j=0; j<stock["news"].length; j++) {
                var item = stock["news"][j]
                item["date_announced"]=new Date(item["date_announced"]).getTime()
                var evt = {}
                /** The order of presenting data is important for rendering in Dividend.vue component 
                 * timestamp, dividend, subject
                 * */
                if (item["type"]) {
                    evt["timeStamp"]=item["date_announced"]               
                    evt["event"]=item["type"]
                    //evt["news"]=item["description"]

                    spl.push(Object.values(evt))                
                }
            }
            this.assignSplitterData(spl,splitters);
        }                        
    },
    mounted() {
        window.addEventListener('resize', this.onResize)
        this.$eventHub.$on('getChartData',this.getChartData)                        
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
        this.$eventHub.$off('getChartData')        
    },

}
</script>

<style lang="scss">
</style>