<template>
    <div class="tabs" id="parent-tabs" ref="portfolioTabs">                
        <template v-if="$store.state.isAuthenticated">                 
            <TabNav :tabs="['Stocks','News','Events']" :selected="selected" @selected="setSelected">
                <Tab :isSelected="selected === 'Stocks'">
                    <h1 class="title">Stocks</h1>
                    
                    <TradingVueJSChart/>  

                    <PortfolioItem
                        :stocks="stocks"
                    />
                </Tab>
                <Tab :isSelected="selected === 'News'">
                    <h1 class="title">Corporate News</h1>
                    <news-summary
                        v-bind:news="news" 
                    />                        
                </Tab>
                <Tab :isSelected="selected === 'Events'">
                    <h1 class="title">Corporate Events</h1>
                    <events-summary
                        v-bind:events="events"
                    />
                </Tab>                                        
            </TabNav>
        </template>
        <template v-else> 
            <br>
            <p>Login to view portfolio</p>
            <router-link to="/log-in" class="button is-light">Log in</router-link>
        </template>  
        <growe-api/>                                    
    </div>
</template>

<script>
import TabNav from '@/components/TabNav.vue'
import Tab from '@/components/Tab.vue'

import GroweApi from './GroweApi.vue'
import TradingVueJSChart from '../components/TradingVueJSChart.vue'
import PortfolioItem from '../components/PortfolioItem.vue'
import NewsSummary from '../components/NewsSummary.vue'
import EventsSummary from '../components/EventsSummary.vue'

export default {
    name: 'Portfolio',
    components: {
        GroweApi,
        TradingVueJSChart,
        PortfolioItem,
        NewsSummary,
        EventsSummary,
        TabNav,
        Tab,
    },
    data() {
        return {
            selected: 'Stocks',   
            store_portfolio: {},        
        }
    },
    created() {
		/**
		 * hack to get state.portfolio.cart and state.watchlist.cart initialized.
		 */
		this.$store.commit('initializeStore')                
    },
	beforeMount() {		
	},    
    mounted() {
        document.title = 'Portfolio | GroWe'
        if(this.$store.state.isAuthenticated) {
            this.store_portfolio = JSON.parse(localStorage.getItem('portfolio'))
            if ( this.store_portfolio["cart"] && this.store_portfolio["cart"].length) {
                this.postMyPortfolio()
            }
            this.$eventHub.$emit('getMyPortfolio') 
        }       
    },        
    computed: {
		stocks() {
            return this.$store.state.portfolio.stocks;
        },
		news() {
            return this.$store.state.portfolio.news;
        },
		events() {
            return this.$store.state.portfolio.events;
        }         
    },
	beforeUpdate(){
	},
	destroyed() {
	},    
    methods: {  
        setSelected(tab) {
            this.selected = tab;
            if (this.selected === 'Stocks') {
                /**
                 * Do nothing because mounted() has already triggered 'getMyPortfolio' 
                 * that has fetched from backend and stored in app state
                 */                
                //this.$eventHub.$emit('getMyPortfolio')
            }
        },
        async postMyPortfolio() {
            this.$store.commit('setIsLoading', true)
            
            const portfolioitems = []

            for (let i=0; i<this.store_portfolio["cart"].length; i++) {
                const stock = this.store_portfolio["cart"][i].stock
                              
                if (Object.keys(stock).length !== 0) {
                    const item = {}
                    item["stock"] = stock.symbol
                    item["quantity"] = stock.quantity
                    portfolioitems.push(item)
                }
            }
            var portfolio_name = localStorage.getItem('username')
            //username is changed to email in the backend. split the email to remove @domain for portfolio name
            portfolio_name = portfolio_name.split('@')[0]
            const data = {
                'name': portfolio_name.concat('-portfolio'),
                'portfolioitems': portfolioitems
            }

            this.$eventHub.$emit('checkoutPortfolio',data)
               
            this.$store.commit('setIsLoading', false)            
        },        
    },            
}
</script>

<style lang="scss">
.tabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px;
}
</style>
