<template>
	<div class="page-product">
		<div class="columns is-multiline">
			<div class="column is-9">	
				<h1 class="title">{{ stock.name }}</h1>

				<p>{{ stock.symbol }}</p>

				<TradingVueJSChart/>  
			</div>		
			<div class="column is-3">
				<h2 class="subtitle">Information</h2>			

				<p><strong>List Date: </strong>{{ stock.list_date }}</p>
				<p>ISIN: {{ stock.isin }}</p>

				<div class="field has-addons mt-6">
					<div class="control">
					<a class="button" @click="addToPortfolioCart">
						<span class="icon">
							<i class="fa fa-plus-circle" aria-hidden="true"></i>
						</span>
						<p class="spacer">Portfolio</p>
					</a>
					</div>				
					<div class="control">
					<a class="button spacer" @click="addToWatchCart">
						<span class="icon">
							<i class="fa fa-plus-circle" aria-hidden="true"></i>
						</span>
						<p class="spacer">Watchlist</p>
					</a>						
					</div>				
				</div>
			</div>
		</div>
	</div>	
</template>

<script type="text/javascript">
import axios from 'axios'
import { toast } from 'bulma-toast'

import TradingVueJSChart from '../components/TradingVueJSChart.vue'

export default {
	name: 'Stock',
	components: {
		TradingVueJSChart
	},
	data() {
		return {
			stock : {},
			quantity: 1
		}
	},
	mounted() {
		this.getStock()	
	},
	methods: {
		async getStock() {
			this.$store.commit('setIsLoading', true)

			const stock_slug = this.$route.params.stock_slug

			await axios
				.get(`/api/v1/stock/${stock_slug}/`)
				.then(response => {
					this.stock = response.data

					document.title = this.stock.name + ' | GroWe' 
				}) 
				.catch(error => {
					console.log(error)
				})
			
			this.$eventHub.$emit('getChartData', this.stock)	

			this.$store.commit('setIsLoading', false)
		},
		is_valid() {
			if (Object.keys(this.stock).length === 0) {
				toast({
					message: 'Something went wrong. Please try again.',
					type: 'is-danger',
					dismissible: true,
					pauseOnHover: true,
					duration: 2000,
					position: 'bottom-right',
				})			
				return false
			} else {
				return true
			}
		},		
		addToWatchCart() {
			if (isNaN(this.quantity) || (this.quantity < 1)) {
				this.quantity = 1
			}
			if (this.is_valid()) {
				const item = {
					stock: this.stock,
					quantity: this.quantity
				}

				this.$store.commit('addToWatchCart', item)

				toast({
					message: 'The stock was added to the watchlist',
					type: 'is-success',
					dismissible: true,
					pauseOnHover: true,
					duration: 2000,
					position: 'bottom-right',
				})
			}
		},
		addToPortfolioCart() {
			if (isNaN(this.quantity) || (this.quantity < 1)) {
				this.quantity = 1
			}
			if (this.is_valid()) {
				const item = {
					stock: this.stock,
					quantity: this.quantity
				}

				this.$store.commit('addToPortfolioCart', item)

				toast({
					message: 'The stock was added to the portfolio',
					type: 'is-success',
					dismissible: true,
					pauseOnHover: true,
					duration: 2000,
					position: 'bottom-right',
				})
			}
		}		
	}
}
	
</script>
<style sass>
.spacer{
	margin-left: 1px;
}
</style>