import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    portfolio: {
      stocks: [],
      news: [],
      events: [],
      cart: [],
    },
    watchlist: {
      stocks: [],
      news: [],
      events: [],
      cart: [],
    },
    scan: {
      stocks: [],
      news: [],
      events: [],
    },    
    isAuthenticated: false,
    username: '',
    token: '',
    isLoading: false,
    reloaded: false
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('portfolio')) {
        state.portfolio = JSON.parse(localStorage.getItem('portfolio'))
      } else {
        localStorage.setItem('portfolio', JSON.stringify(state.portfolio))
      }

      if (localStorage.getItem('watchlist')) {
        state.watchlist = JSON.parse(localStorage.getItem('watchlist'))
      } else {
        localStorage.setItem('watchlist', JSON.stringify(state.watchlist))
      }

      if (localStorage.getItem('scan')) {
        state.scan = JSON.parse(localStorage.getItem('scan'))
      } else {
        localStorage.setItem('scan', JSON.stringify(state.scan))
      }

      if (localStorage.getItem('token')) {
        state.token = localStorage.getItem('token')
        state.isAuthenticated = true
      } else {
        state.token = ''
        state.isAuthenticated = false
      }
    },
    addToWatchCart(state, item) {
      const exists = state.watchlist.cart.filter(i => i.stock.id === item.stock.id)

      if (exists.length === 0) {        
        state.watchlist.cart.push(item)
      }

      localStorage.setItem('watchlist', JSON.stringify(state.watchlist))
    },  
    addToPortfolioCart(state, item) {
      const exists = state.portfolio.cart.filter(i => i.stock.id === item.stock.id)

      if (exists.length === 0) {        
        state.portfolio.cart.push(item)
      }

      localStorage.setItem('portfolio', JSON.stringify(state.portfolio))
    },
    addToWatchStocks(state, item) {
      const exists = state.watchlist.stocks.filter(i => i.id === item.id)

      if (exists.length === 0) {        
        state.watchlist.stocks.push(item)
      }

      localStorage.setItem('watchlist', JSON.stringify(state.watchlist))
    },  
    addToWatchNews(state, item) {
      const exists = state.watchlist.news.filter(i => i.id === item.id)

      if (exists.length === 0) {        
        state.watchlist.news.push(item)
      }

      localStorage.setItem('watchlist', JSON.stringify(state.watchlist))
    },
    addToWatchEvents(state, item) {
      const exists = state.watchlist.events.filter(i => i.id === item.id)

      if (exists.length === 0) {        
        state.watchlist.events.push(item)
      }

      localStorage.setItem('watchlist', JSON.stringify(state.watchlist))
    },        
    addToPortfolioStocks(state, item) {
      const exists = state.portfolio.stocks.filter(i => i.id === item.id)

      if (exists.length === 0) {        
        state.portfolio.stocks.push(item)
      }

      localStorage.setItem('portfolio', JSON.stringify(state.portfolio))
    },    
    addToPortfolioNews(state, item) {
      const exists = state.portfolio.news.filter(i => i.id === item.id)

      if (exists.length === 0) {        
        state.portfolio.news.push(item)
      }

      localStorage.setItem('portfolio', JSON.stringify(state.portfolio))
    },    
    addToPortfolioEvents(state, item) {
      const exists = state.portfolio.events.filter(i => i.id === item.id)

      if (exists.length === 0) {        
        state.portfolio.events.push(item)
      }

      localStorage.setItem('portfolio', JSON.stringify(state.portfolio))
    },
    addToScanStocks(state, item) {
      const exists = state.scan.stocks.filter(i => i.id === item.id)

      if (exists.length === 0) {        
        state.scan.stocks.push(item)
      }

      localStorage.setItem('scan', JSON.stringify(state.scan))
    },    
    addToScanNews(state, item) {
      const exists = state.scan.news.filter(i => i.id === item.id)

      if (exists.length === 0) {        
        state.scan.news.push(item)
      }

      localStorage.setItem('scan', JSON.stringify(state.scan))
    },    
    addToScanEvents(state, item) {
      const exists = state.scan.events.filter(i => i.id === item.id)

      if (exists.length === 0) {        
        state.scan.events.push(item)
      }

      localStorage.setItem('scan', JSON.stringify(state.scan))
    },
    
    setIsLoading(state, status) {
      state.isLoading = status
    },
    setToken(state, token) {
      state.token = token
      state.isAuthenticated = true
    },
    removeToken(state) {
      state.token = ''
      state.isAuthenticated = false
    },
    setUsername(state, uname) {
      state.username = uname
    },
    removeUsername(state) {
      state.username=''
    },
    setReloaded(state) {
      state.reloaded = true
    },            
    removeReloaded(state) {
      state.reloaded = false
    },        
    clearPortfolio(state) {
      state.portfolio = { 
        stocks: [],
        news: [],
        events: [],
        cart: []
      }

      localStorage.setItem('portfolio', JSON.stringify(state.portfolio))
    },
    clearWatchlist(state) {
      state.watchlist = {  
        stocks: [],
        news: [],
        events: [],
        cart: []
      }

      localStorage.setItem('watchlist', JSON.stringify(state.watchlist))
    },
    clearScan(state) {
      state.scan = { 
        stocks: [],
        news: [],
        events: []
      }

      localStorage.setItem('scan', JSON.stringify(state.scan))
    },        
  },
  actions: {
  },
  getters: {
  },
  modules: {
  }
})
