<!-- GroweApi.vue -->
<template>
    <div></div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'GroweApi',    
    data() {
        return {}
    },
    methods: {  
        async checkoutPortfolio(data) {
            await axios
                .post('/api/v1/portfolio/checkout/', data)
                .then(response => {
                    this.$store.commit('clearPortfolio')
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async getMyPortfolio() {
            this.$store.commit('setIsLoading', true)

            await axios
                .get('/api/v1/portfolio/')
                .then(response => {
                    this.getMyPortfolioItems(response.data)
                })
                .catch(error => {
                    console.log(error)
                })
               
            this.$store.commit('setIsLoading', false)            
        },
        getMyPortfolioItems(data) {
            /*
            ToDo: handle multiple portfolios
            */
            if ( data !== "undefined" 
            && data[0].portfolioitems.length 
            && (typeof data[0].portfolioitems[0].stock.name !== "undefined")
            ) {
                for (let i=0; i<data[0].portfolioitems.length; i++) {
                    const stock = data[0].portfolioitems[i].stock
                    const obj = {
                        id: stock.id,
                        name: stock.name,
                        symbol: stock.symbol,
                        quantity: stock.quantity,
                        list_date: stock.list_date,
                        isin: stock.isin,
                        ltp: stock.price.c
                    }
                    this.updateStorePortfolioStocks(obj)

                    for (let j=0; j<stock.announcements.length; j++) {
                        const anObj = {
                            id: stock.announcements[j].id,                            
                            stockname: stock.announcements[j].stockname,
                            title: stock.announcements[j].title,
                            description: stock.announcements[j].description,
                            attachment: stock.announcements[j].attachment,
                            date_announced: stock.announcements[j].date_announced
                        }
                        this.updateStorePortfolioNews(anObj)                       
                    }

                    for (let k=0; k<stock.events.length; k++) {
                        const eObj = {
                            id: stock.events[k].id,                            
                            stockname: stock.events[k].stockname,
                            subject: stock.events[k].subject,
                            exdate:  stock.events[k].exdate,
                            recdate:  stock.events[k].recdate
                        } 
                        this.updateStorePortfolioEvents(eObj)
                    }                    
                }
            } else {
                console.log("Portfolio is empty!")
            }
        },
        getStorePortfolio() {
            return JSON.parse(localStorage.getItem('portfolio'))
        },
        /**
         * ToDo: make the state updates in store setter functions instead of 
         * doing it here.
         */
        updateStorePortfolioStocks(stock) {                       
			this.$store.commit('addToPortfolioStocks', stock)
        },                
        updateStorePortfolioNews(news) {
			this.$store.commit('addToPortfolioNews', news)
        },                
        updateStorePortfolioEvents(event) {
			this.$store.commit('addToPortfolioEvents', event)
        },        
        async checkoutWatchlist(data) {
            await axios
                .post('/api/v1/watchlist/checkout/', data)
                .then(response => {
                    this.$store.commit('clearWatchlist')
                })
                .catch(error => {
                    console.log(error)
                })        
        },          
        async runScan() {
            this.$store.commit('setIsLoading', true)
            this.stocks = []
            this.events = []
            this.news = []
            let morePages = true
            console.log('Run scanner: /api/v1/scan/pages')
            let url = '/api/v1/scan/pages'
            do {
                /**
                 * ToDo: break ongoing loop when a new request is triggered.
                 */
                await axios
                    .get(url)
                    .then(response => {
                        this.getScanItems(response.data)
                        if(response.data == null || response.data['next'] == null){
                            morePages = false
                       }
                       else{
                           /**
                            * is the backend in production(growe.in) or local environment(x.x.x.x:8000?
                            */
                           const str = response.data['next'].search('.in')
                           if(str != -1) {
                               url = response.data['next'].split('.in')[1]
                           }else{
                               url = response.data['next'].split(':8000')[1]
                           }
                       }
                    })
                    .catch(error => {
                        console.log(error)
                        morePages = false
                    })
            } while(morePages);               
            this.$store.commit('setIsLoading', false)            
        },
        getScanItems(data) {
            let length = data['results'].length
            if ( data !== "undefined" 
            && length 
            && (typeof data['results'][0].stock.name !== "undefined")
            ) {
                for (let i=0; i<length; i++) {
                    const stock = data['results'][i].stock
                    const obj = {
                        id: stock.id,
                        name: stock.name,
                        symbol: stock.symbol,
                        list_date: stock.list_date,
                        isin: stock.isin
                    }
                    this.updateStoreScanStocks(obj)

                    for (let j=0; j<stock.announcements.length; j++) {
                        const anObj = {
                            id: stock.announcements[j].id,
                            stockname: stock.announcements[j].stockname,
                            title: stock.announcements[j].title,
                            description: stock.announcements[j].description,
                            attachment: stock.announcements[j].attachment,
                            date_announced: stock.announcements[j].date_announced
                        }
                        this.updateStoreScanNews(anObj)
                    }

                    for (let k=0; k<stock.events.length; k++) {
                        const eObj = {
                            id: stock.events[k].id,
                            stockname: stock.events[k].stockname,
                            subject: stock.events[k].subject,
                            exdate:  stock.events[k].exdate,
                            recdate:  stock.events[k].recdate
                        } 
                        this.updateStoreScanEvents(eObj)
                    }                    
                }           
            } else {
                console.log("Scan didn't find stocks that yeild buy or sell signal!")
            }
        },
        updateStoreScanStocks(stock) {
            this.$store.commit('addToScanStocks',stock)
        },                
        updateStoreScanNews(news) {
			this.$store.commit('addToScanNews', news)
        },                
        updateStoreScanEvents(event) {
			this.$store.commit('addToScanEvents', event)
        },                

        async getMyWatchlist() {
            this.$store.commit('setIsLoading', true)

            await axios
                .get('/api/v1/watchlist/')
                .then(response => {
                    this.getMyWatchItems(response.data)
                })
                .catch(error => {
                    console.log(error)
                })
               
            this.$store.commit('setIsLoading', false)            
        },
        getMyWatchItems(data) {
            /*
            ToDo: handle multiple portfolios
            */
            if ( data !== "undefined" 
            && data[0].watchitems.length 
            && (typeof data[0].watchitems[0].stock.name !== "undefined")
            ) {
                for (let i=0; i<data[0].watchitems.length; i++) {
                    const stock = data[0].watchitems[i].stock
                    const obj = {
                        id: stock.id,
                        name: stock.name,
                        symbol: stock.symbol,
                        quantity: stock.quantity,
                        list_date: stock.list_date,
                        isin: stock.isin,
                        ltp: stock.price.c
                    }
                    this.updateStoreWatchlistStocks(obj)

                    for (let j=0; j<stock.announcements.length; j++) {
                        const anObj = {
                            id: stock.announcements[j].id,                            
                            stockname: stock.announcements[j].stockname,
                            title: stock.announcements[j].title,
                            description: stock.announcements[j].description,
                            attachment: stock.announcements[j].attachment,
                            date_announced: stock.announcements[j].date_announced
                        }
                        this.updateStoreWatchlistNews(anObj)                       
                    }

                    for (let k=0; k<stock.events.length; k++) {
                        const eObj = {
                            id: stock.events[k].id,                            
                            stockname: stock.events[k].stockname,
                            subject: stock.events[k].subject,
                            exdate:  stock.events[k].exdate,
                            recdate:  stock.events[k].recdate
                        } 
                        this.updateStoreWatchlistEvents(eObj)
                    }                    
                }
            } else {
                console.log("Watchlist is empty!")
            }
        },

        getStoreWatchlist() {
            return JSON.parse(localStorage.getItem('watchlist'))
        },
        /**
         * ToDo: make the state updates in store setter functions instead of 
         * doing it here.
         */
        updateStoreWatchlistStocks(stock) {
            this.$store.commit('addToWatchStocks',stock)
        },                
        updateStoreWatchlistNews(news) {
			this.$store.commit('addToWatchNews', news)
        },                
        updateStoreWatchlistEvents(event) {
			this.$store.commit('addToWatchEvents', event)
        },                
    },
    mounted() {
        this.$eventHub.$on('getMyPortfolio', this.getMyPortfolio);
        this.$eventHub.$on('checkoutPortfolio', this.checkoutPortfolio);
        this.$eventHub.$on('runScan', this.runScan);        
        this.$eventHub.$on('getMyWatchlist', this.getMyWatchlist);        
        this.$eventHub.$on('checkoutWatchlist', this.checkoutWatchlist);
    },
    destroyed() {
        this.$eventHub.$off('getMyPortfolio');
        this.$eventHub.$off('checkoutPortfolio');
        this.$eventHub.$off('runScan');
        this.$eventHub.$off('getMyWatchlist');        
        this.$eventHub.$off('checkoutWatchlist');        
    }
}
</script>
