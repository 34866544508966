<template>
    <div>
        <vue-good-table
            :columns="columns"
            :rows="rows"
            @on-row-click="goToNewsPage"
            :pagination-options="{
                enabled:true,
                mode: 'records',
                perPage: 20
            }"
            styleClass="vgt-table condensed bordered"            
            >
        </vue-good-table>                
    </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';


export default {
    name: 'NewsSummary',
    components: {
        VueGoodTable,
    },
    props: {
        news: [],
    },
    data() {
        return {
            columns: [
                {
                    label:'Stock',
                    field: 'stockname',
                },
                {
                    label:'Date',
                    field: 'date_announced',
                },
                {
                    label:'Title',
                    field: 'title',
                },
                {
                    label:'Description',
                    field: 'description',
                },
            ],
            rows: this.news,
        }
    },        
    methods: {
        goToNewsPage(params) {
            window.open(params.row.attachment)
        }
    }
 }
</script>
