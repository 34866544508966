<template>
    <div class="tabs" id="parent-tabs" ref="scannerTabs">                
        <template v-if="$store.state.isAuthenticated">                 
            <TabNav :tabs="['Stocks','News','Events']" :selected="selected" @selected="setSelected">
                <Tab :isSelected="selected === 'Stocks'">
                    <h1 class="title">Stocks</h1>
                    
                    <TradingVueJSChart/>  

                    <ScanItem
                        :stocks="stocks"
                    />
                </Tab>
                <Tab :isSelected="selected === 'News'">
                    <h1 class="title">Corporate News</h1>
                    <news-summary
                        v-bind:news="news" 
                    />                        
                </Tab>
                <Tab :isSelected="selected === 'Events'">
                    <h1 class="title">Corporate Events</h1>
                    <events-summary
                        v-bind:events="events"
                    />
                </Tab>                                        
            </TabNav>
        </template>
        <template v-else> 
            <br>
            <p>Login to view scan results</p>
            <router-link to="/log-in" class="button is-light">Log in</router-link>
        </template>  
        <growe-api/>                                    
    </div>
</template>

<script>
import TabNav from '@/components/TabNav.vue'
import Tab from '@/components/Tab.vue'

import GroweApi from './GroweApi.vue'
import TradingVueJSChart from '../components/TradingVueJSChart.vue'
import ScanItem from '../components/ScanItem.vue'
import NewsSummary from '../components/NewsSummary.vue'
import EventsSummary from '../components/EventsSummary.vue'

export default {
    name: 'Scanner',
    components: {
        GroweApi,
        TradingVueJSChart,
        ScanItem,
        NewsSummary,
        EventsSummary,
        TabNav,
        Tab,
    },
    data() {
        return {
            selected: 'Stocks',   
            store_scan: {},        
        }
    },
    created() {
		/**
		 * hack to get state.portfolio.cart and state.watchlist.cart initialized.
		 */
		this.$store.commit('initializeStore')                
    },
	beforeMount() {		
	},    
    mounted() {
        document.title = 'Scanner | GroWe'
        if(this.$store.state.isAuthenticated) {
            this.store_scan = JSON.parse(localStorage.getItem('scan'))
            this.$eventHub.$emit('runScan') 
        }       
    },        
    computed: {
		stocks() {
            return this.$store.state.scan.stocks;
        },
		news() {
            return this.$store.state.scan.news;
        },
		events() {
            return this.$store.state.scan.events;
        }         
    },
	beforeUpdate(){
	},
	destroyed() {
	},    
    methods: {  
        setSelected(tab) {
            this.selected = tab;
            if (this.selected === 'Stocks') {
                /**
                 * Do nothing because mounted() has already triggered 'getMyPortfolio' 
                 * that has fetched from backend and stored in app state
                 */                
                //this.$eventHub.$emit('runScan')
            }
        },
    },            
}
</script>

<style lang="scss">
.tabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0px;
}
</style>
