<template>
	<div class="page-my-account">
		<div class="columns is-multiline">
			<div class="column is-12">
				<h1 class="title">My account</h1>				
			</div>

			<div class="column is-12 is-offset-10">
				<button @click="logout()" class="button">
                    <span class="icon">
                      <i class="fa fa-sign-out-alt" aria-hidden="true"></i>
                    </span>                
                    <p class="spacer">Log out</p>
				</button>				
			</div>

			<hr>
		</div>
		<div class="rows">
            <growe-api/>
			<h2 class="subtitle">Portfolio</h2>
			<div class="row is-full">				
                <TradingVueJSChart/>  
			</div>
			<div class="row is-full">				                
                <PortfolioItem
                    :stocks="stocks"                
                />
			</div>
		</div>	
		</div>
	</div>
	
</template>

<script>
import axios from 'axios'	

import TradingVueJSChart from '@/components/TradingVueJSChart.vue'
import PortfolioItem from '@/components/PortfolioItem.vue'
import GroweApi from './GroweApi.vue'

export default {
	name: 'MyAccount',
	components: {
        TradingVueJSChart,        
		PortfolioItem,
        GroweApi,
	},
	data() {
		return {
		}
	},
	created() {
		/**
		 * hack to get state.portfolio.cart and state.watchlist.cart initialized.
		 */		
		this.$store.commit('initializeStore');		
	},
	beforeMount() {		
	},
	mounted() {
		document.title = 'My account | GroWe'
		this.$eventHub.$emit('getMyPortfolio');							
	},
    computed: {
		stocks() {
            return this.$store.state.portfolio.stocks;
        },		
    },
	beforeUpdate(){
	},
	destroyed() {
	},
	methods: {
		logout() {
			axios.defaults.headers.common["Authorization"] = ""

			localStorage.removeItem("token")
			localStorage.removeItem("username")
			//localStorage.removeItem("userid")

			this.$store.commit('removeToken')
            this.$store.commit('clearPortfolio')
            this.$store.commit('clearWatchlist')

			this.$router.push('/')
		},
	},        
}	
</script>
<style lang="scss">
.rows {
  display: flex;
  flex-direction: column;
}
</style>