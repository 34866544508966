<template>
	<div></div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

export default {
	name: 'ActivateAccount',
	data() {
		return	{
			errors:[],
		}
	},
	mounted(){
		const formData = {
			uid:this.$route.params.uid,
			token:this.$route.params.token,
		}
		const url = "/api/v1/account/activate/"+formData.uid+"/"+formData.token
		console.log(url)
		axios
			.get(url)
			.then(response => {
				toast({
					message: 'Account activated, please log in!',
					type: 'is-success',
					dismissible: true,
					pauseOnHover: true,
					duration: 2000,
					position: 'bottom-right',
				})

				this.$router.push('/log-in')
			})
			.catch(error => {
				if (error.response) {
					for (const property in error.response.data) {
						this.errors.push(`${property}: ${error.response.data[property]}`)
					}

					console.log(JSON.stringify(error.response.data))
				} else if (error.message) {
					this.errors.push('Something went wrong. Please try again')

					console.log(JSON.stringify(error))
				}
			})
	},
}
</script>