<template>
    <div class="page-my-tab-watchlist" id="parent-tabs">  
        <growe-api/>
        <div class="tabs">  
			<template v-if="$store.state.isAuthenticated">                   
                <TabNav :tabs="['Stocks','News','Events']" :selected="selected" @selected="setSelected">
                    <Tab :isSelected="selected === 'Stocks'">
                        <h1 class="title">Watchlist</h1>
                        <TradingVueJSChart/>  

                        <watchlist-item
                            :stocks ="stocks"
                        />
                    </Tab>
                    <Tab :isSelected="selected === 'News'">
                        <h1 class="title">Corporate News</h1>
                        <NewsSummary
                            v-bind:news="news" 
                        />                        
                    </Tab>
                    <Tab :isSelected="selected === 'Events'">
                        <h1 class="title">Corporate Events</h1>
                        <EventsSummary
                            v-bind:events="events"
                        />
                    </Tab>                                        
                </TabNav>
            </template>
			<template v-else> 
				<br>
				<p>Login to view watchlist</p>
				<router-link to="/log-in" class="button is-light">Log in</router-link>
			</template>            
        </div>
    </div>
</template>

<script>
import GroweApi from './GroweApi.vue'
import TradingVueJSChart from '../components/TradingVueJSChart.vue'
import WatchlistItem from '@/components/WatchlistItem.vue'
import NewsSummary from '@/components/NewsSummary.vue'
import EventsSummary from '@/components/EventsSummary.vue'
import TabNav from '@/components/TabNav.vue'
import Tab from '@/components/Tab.vue'

export default {
    name: 'Watchlist',
    components: {
        GroweApi,
        TradingVueJSChart,
        WatchlistItem,
        NewsSummary,
        EventsSummary,
        TabNav,
        Tab
    },
    data() {
        return {
            selected: 'Stocks',
            store_watchlist: {},
        }
    },
    created() {
		/**
		 * hack to get state.portfolio.cart and state.watchlist.cart initialized.
		 */
		this.$store.commit('initializeStore')
    },    
	beforeMount() {		
	},    
    mounted() {
        document.title = 'Watchlist | GroWe'
        if (this.$store.state.isAuthenticated) {
            this.store_watchlist = JSON.parse(localStorage.getItem('watchlist'))
            if ( this.store_watchlist["cart"] && this.store_watchlist["cart"].length) {
                this.postMyWatchlist()
            }
            this.$eventHub.$emit('getMyWatchlist')
        }
    },
    computed: {
		stocks() {
            return this.$store.state.watchlist.stocks;
        },
		news() {
            return this.$store.state.watchlist.news;
        },
		events() {
            return this.$store.state.watchlist.events;
        } 
    },   
	beforeUpdate(){
	},
	destroyed() {
	},     
    methods: {
        setSelected(tab) {
            this.selected = tab;
            if (this.selected === 'Stocks') {
                /**
                 * Do nothing because mounted() has already triggered 'getMyPortfolio' 
                 * that has fetched from backend and stored in app state
                 */                
                /*
                if (!this.$store.state.watchlist.stocks.length) {                
                    this.$eventHub.$emit('getMyWatchlist')
                }
                */
            }
        },
        async postMyWatchlist() {
            this.$store.commit('setIsLoading', true)
            
            const watchitems = []

            for (let i=0; i<this.store_watchlist["cart"].length; i++) {
                const stock = this.store_watchlist["cart"][i].stock
                              
                if (Object.keys(stock).length !== 0) {
                    const item = {}
                    item["stock"] = stock.symbol
                    watchitems.push(item)
                }
            }
            var watchlist_name = localStorage.getItem('username')
            //username is changed to email in the backend. split the email to remove @domain for portfolio name
            watchlist_name = watchlist_name.split('@')[0]
            const data = {
                'name': watchlist_name.concat('-watchlist'),
                'watchitems': watchitems
            }
            
            this.$eventHub.$emit('checkoutWatchlist',data)
        },
    },
}
</script>

<style lang="scss">
#parent-tabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
</style>
