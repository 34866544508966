<template>
        <vue-good-table
            ref="portfolio-table"
            @on-selected-rows-change="selectionChanged"            
            :columns="columns"
            :rows="stocks"
            :fixed-header="true"
            :pagination-options="{
                enabled:true,
                mode: 'records',
                perPage: 5
            }"
            :select-options="{ 
                enabled: true,
            }"
            styleClass="vgt-table condensed bordered"
            :search-options="{ enabled: true }"                    
            @on-row-click="getChartData">
        <div slot="selected-row-actions">         
            <button v-on:click="deleteFromPortfolio">Delete</button>
        </div>                                    
        </vue-good-table>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import axios from 'axios';

export default {
    name: 'PortfolioItem',
    components: {
        VueGoodTable,
    },
    
    props: {
        stocks: [],
    },
    
    data() {
        return {
            columns: [
                {
                    label: 'Name',
                    field: 'name',
                },
                {
                    label: 'Symbol',
                    field: 'symbol',
                },
                {
                    label: 'LTP',
                    field: 'ltp',
                },            
            ],      
        }
    },
    mounted() {
        this.$eventHub.$emit('getChartData', this.stocks[0])
    },
    methods: {
        selectionChanged(params){
        },              
        updatePortfolio() {
            localStorage.setItem('portfolio', JSON.stringify(this.$store.state.portfolio))
        },
        deleteFromPortfolio() {
            var rstocks = this.$refs['portfolio-table'].selectedRows
            for(let i=0; i<rstocks.length; i++){
                console.log(rstocks[i])
                this.removeFromPortfolio(rstocks[i])
            }
            this.updatePortfolio()
        },                
        removeFromPortfolio(stock) {
            this.stocks = this.stocks.filter(i => i.symbol !== stock.symbol)
            axios            
                .delete(`/api/v1/portfolio/${stock.id}`)
                .then(response => {
                    this.news = response.data
                })
                .catch(error => {
                    console.log(error)
                })            
        },   
        getChartData(params) {
            this.$eventHub.$emit('getChartData', params.row)
        },
    }
 }
</script>
