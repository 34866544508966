<template>
	<div class="page-search">		
		<div class="columns is-multiline">
			<div class="column is-12">
				<h1 class="title">Search</h1>

				<h2 class="is-size-5 has-text-grey">Search term: "{{ query }}"</h2>
			</div>			
			<StockBox
        		v-for="stock in stocks"
        		v-bind:key="stock.id"
        		v-bind:stock="stock"/>
		</div>
	</div>
</template>

<script>
import axios from 'axios'	
import StockBox from '@/components/StockBox.vue'

export default {
	name: 'Search',
	components: {
		StockBox,
	},
	data() {
		return {
			stocks: [],
			query: ''
		}
	},
	created() {
		/**
		 * hack to get state.portfolio.cart and state.watchlist.cart initialized.
		 */
		this.$store.commit('initializeStore')
	},
	mounted() {
		document.title = 'Search | GroWe'

		let uri = window.location.search.substring(1)
		let params = new URLSearchParams(uri)

		if (params.get('query')) {
			this.query = params.get('query')

			this.performSearch()
		}
	},
	methods: {
		async performSearch() {
			this.$store.commit('setIsLoading', true)

			await axios
				.post('/api/v1/search/', {'query': this.query})
				.then(response => {
					this.stocks = response.data
				})
				.catch(error => {
					console.log(error)
				})
			this.$store.commit('setIsLoading', false)				
		}
	}
}
</script>