<template>
<div class="chart-box">
    <trading-vue :id="id" :data="chart" :titleTxt = "title"
            :chart-config="{ DEFAULT_LEN: 120 }"
            :width="width" :height="height"
            :color-title="colors.colorTitle"
            :color-back="colors.colorBack"
            :color-grid="colors.colorGrid"
            :color-text="colors.colorText"
            :overlays="overlays">
    </trading-vue>
</div>
</template>

<script>
import {TradingVue,DataCube} from 'trading-vue-js'
//import Dividend from '@/components/Dividend/Dividend.vue'
import Buy from '@/components/Buy/Buy.vue'
import Sell from '@/components/Sell/Sell.vue'


export default {
    name: 'Chartbox',
    props: ['id', 'tf', 'data', 'width', 'height'],
    components: {
        TradingVue
    },
    mounted() {

    },
    methods: {

    },
    computed: {
        chart() {
            //return { ohlcv: new DataCube(this.$props.data['chart']) }
            //return { ohlcv: this.$props.data['chart']['data'] }
            return { 
                chart: this.$props.data['chart'],
                onchart: this.$props.data['onchart']
             }
        },
        title() {
            return this.$props.tf
        }
    },
    data() {
        return {
            colors: {
                colorBack: '#fff',
                colorGrid: '#eee',
                colorText: '#333',
                colorTitle: '#333'
            },
            //overlays: [Dividend,Buy,Sell]
            overlays: [Buy,Sell]
        };
    }
};
</script>

<style>
.chart-box {
    border: 0.5px solid transparent;
}
</style>
