import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import Stock from '../views/Stock.vue'
import Search from '../views/Search.vue'
import SignUp from '../views/SignUp.vue'
import LogIn from '../views/LogIn.vue'
import MyAccount from '../views/MyAccount.vue'
import Portfolio from '../views/Portfolio.vue'
import Watchlist from '../views/Watchlist.vue'
import Multichart from '../views/Multichart.vue'
import ResetPassword from '../views/ResetPassword'
import ActivateAccount from '../views/ActivateAccount'
import Scanner from '../views/Scanner.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  /*
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp
  },
  */   
  {
    path: '/log-in',
    name: 'LogIn',
    component: LogIn
  },   
  {
    path: '/account/activate/:uid/:token',
    name: 'ActivateAccount',
    component: ActivateAccount
  },   
  {
    path: '/reset/password/:uid/:token',
    name: 'ResetPassword',
    component: ResetPassword
  },   
  {
    path: '/my-account',
    name: 'MyAccount',
    component: MyAccount,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/watchlist',
    name: 'Watchlist',
    component: Watchlist,
    meta: { 
      requireLogin: true
    }
  },    
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: Portfolio,
    meta: { 
      requireLogin: true
    }
  },
  {
    path: '/multichart',
    name: 'Multichart',
    component: Multichart,
    meta: { 
      requireLogin: true
    }
  },  
  {
    path: '/search',
    name: 'Search',
    component: Search
  }, 
  {
    path: '/scanner',
    name: 'Scanner',
    component: Scanner,
    meta: { 
      requireLogin: true
    }
  },         
  {
    path: '/:stock_slug',
    name: 'Stock',
    component: Stock,
    meta: { 
      requireLogin: true
    }    
  },
]

const router = new VueRouter({
//  history: createWebHistory(process.env.BASE_URL),  
  routes
})


export default router
