<template>
	<div class="page-password-reset">
		<div class="columns">
			<div class="column">
				<h1 class="title">Reset Password</h1>

				<form @submit.prevent="submitForm">		
					<div class="field">
						<label>New Password</label>
						<div class="control">
							<input type="password" class="input" v-model="password">
						</div>						
					</div>

					<div class="field">
						<label>Repeat Password</label>
						<div class="control">
							<input type="password" class="input" v-model="password2">
						</div>						
					</div>

					<div class="notification is-danger" v-if="errors.length">
						<p v-for="error in errors" v-bind:key="error">{{ error }}</p>						
					</div>

					<div class="field">
						<div class="control">
							<button class="button">Submit</button>
						</div>						
					</div>

					<hr>

					Or <router-link to="/log-in">click here</router-link> to log in!
				</form>				
			</div>			
		</div>		
	</div>
</template>

<script>
import axios from 'axios'	
import { toast } from 'bulma-toast'

export default {
	name: 'ResetPassword',
	data() {
		return	{
			password: '',
			password2: '',
			errors: []
		}
	},
	methods: {
		submitForm() {
			this.errors = [];
			if (this.password === ''){
				this.errors.push('The password is too short');
			}

			if (this.password !== this.password2){
				this.errors.push('The passwords do not match');
			}

			if (!this.errors.length) {
				console.log(this.$route.params.uid, this.$route.params.token)
				const formData = {
					uid:this.$route.params.uid,
					token:this.$route.params.token,
					new_password: this.password,
				}

				axios
					.post("/api/v1/users/reset_password_confirm/", formData)
					.then(response => {
						toast({
							message: 'Account created, please log in!',
							type: 'is-success',
							dismissible: true,
							pauseOnHover: true,
							duration: 2000,
							position: 'bottom-right',
						})

						this.$router.push('/log-in')
					})
					.catch(error => {
						if (error.response) {
							for (const property in error.response.data) {
								this.errors.push(`${property}: ${error.response.data[property]}`)
							}

							console.log(JSON.stringify(error.response.data))
						} else if (error.message) {
							this.errors.push('Something went wrong. Please try again')

							console.log(JSON.stringify(error))
						}
					})
			}

		}
	}
}
</script>