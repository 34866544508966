<template>
	<div class="column is-3">
		<div class="box">
			<h3 class="is-size-4">{{ stock.name }}</h3>
			<p class="is-size-6 has-text-grey">Symbol: {{ stock.symbol }}</p>
			<p class="is-size-6 has-text-grey">List Date: {{ stock.list_date }}</p>		
			<br>
			<template v-if="$store.state.isAuthenticated"> 
				<div class="buttons">
					<a class="button" @click="addToWatchCart">
						<span class="icon">
							<i class="fa fa-plus-circle" aria-hidden="true"></i>
						</span>
						<p class="spacer">Watchlist</p>
					</a>
					<a class="button" @click="addToPortfolioCart">
						<span class="icon">
							<i class="fa fa-plus-circle" aria-hidden="true"></i>
						</span>
						<p class="spacer">Portfolio</p>
					</a>
				</div>				

				<router-link v-bind:to="stock.get_absolute_url" class="button">
					<span class="icon">
						<i class="fa fa-eye" aria-hidden="true"></i>
					</span>
					<p class="spacer">Details</p>
				</router-link>
			</template>
			<template v-else> 
				<br>
				<p><b>Login to view insights on price chart, add to portfolio and/or watchlist.</b></p>
				<router-link to="/log-in" class="button is-light">Log in</router-link>
			</template>
		</div>		
	</div>
</template>

<script>
import { toast } from 'bulma-toast'
import Tab from './Tab.vue'

export default {
  components: { Tab },
	name: 'StockBox',
	props: {
		stock: Object
	},
	methods: {
		is_valid() {
			if (Object.keys(this.stock).length === 0) {
				toast({
					message: 'Something went wrong. Please try again.',
					type: 'is-danger',
					dismissible: true,
					pauseOnHover: true,
					duration: 2000,
					position: 'bottom-right',
				})			
				return false
			} else {
				return true
			}
		},
		/* re-use code from Stock.vue to add to watchlist and portfolio*/
		addToWatchCart() {
			if (isNaN(this.quantity) || (this.quantity < 1)) {
				this.quantity = 1
			}
			if (this.is_valid()) {
				const item = {
					stock: this.stock,
					quantity: this.quantity
				}

				this.$store.commit('addToWatchCart', item)

				toast({
					message: 'The stock was added to the watchlist',
					type: 'is-success',
					dismissible: true,
					pauseOnHover: true,
					duration: 2000,
					position: 'bottom-right',
				})
			}
		},		
		addToPortfolioCart() {
			if (isNaN(this.quantity) || (this.quantity < 1)) {
				this.quantity = 1
			}
			if (this.is_valid()) {
				const item = {
					stock: this.stock,
					quantity: this.quantity
				}

				this.$store.commit('addToPortfolioCart', item)

				toast({
					message: 'The stock was added to the portfolio',
					type: 'is-success',
					dismissible: true,
					pauseOnHover: true,
					duration: 2000,
					position: 'bottom-right',
				})
			}
		}		
	}
}
	
</script>


<style scoped>
  .image {
    margin-top: -1.25rem;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }  

.spacer{
	margin-left: 1px;
}
</style>