<template>
        <vue-good-table
            ref="watch-table"
            @on-selected-rows-change="selectionChanged"
            :columns="columns"
            :rows="stocks"
            :fixed-header="true"
            :pagination-options="{
                enabled:true,
                mode: 'records',
                perPage: 5
            }"
            :select-options="{ 
                enabled: true,
            }"
            styleClass="vgt-table condensed bordered"            
            :search-options="{ enabled: true }"
            @on-row-click="getChartData">                        
        <div slot="selected-row-actions">         
            <button v-on:click="deleteFromWatchlist">Delete</button>
        </div>                        
        </vue-good-table>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import axios from 'axios';

export default {
    name: 'WatchlistItem',
    components: {
        VueGoodTable,
    },
    
    props: {
        stocks: [],
    },
    
    data() {
        return {
            columns: [
                {
                    label: 'Name',
                    field: 'name',
                },
                {
                    label: 'Symbol',
                    field: 'symbol',
                },
                {
                    label: 'List Date',
                    field: 'list_date',
                }
            ],      
        }
    },
    mounted() {
        this.$eventHub.$emit('getChartData', this.stocks[0])
    },    
    methods: {
        selectionChanged(params){
            //console.log(params["selectedRows"])
        },
        updateWatchlist() {
            localStorage.setItem('watchlist', JSON.stringify(this.$store.state.watchlist))
        },
        deleteFromWatchlist() {
            var rstocks = this.$refs['watch-table'].selectedRows
            for(let i=0; i<rstocks.length; i++){
                console.log(rstocks[i])
                this.removeFromWatch(rstocks[i])
            }
            this.updateWatchlist()
        },
        removeFromWatch(stock) {
            this.stocks = this.stocks.filter(i => i.symbol !== stock.symbol)
            axios            
                .delete(`/api/v1/watchlist/${stock.id}`)
                .then(response => {
                    this.news = response.data
                })
                .catch(error => {
                    console.log(error)
                })            
        },   
        getChartData(params) {
            this.$eventHub.$emit('getChartData', params.row)
        },                                   
    }
 }
</script>
