<template>
	<div class="page-sign-up">
		<div class="columns">
			<div class="column">
				<h1 class="title">Log In</h1>

				<form @submit.prevent="submitForm">		
					<div class="field">
						<label>Email</label>
						<div class="control">
							<input type="text" class="input" v-model="username" required>
						</div>						
					</div>

					<div class="field">
						<label>Password</label>
						<div class="control">
							<input type="password" class="input" v-model="password">
						</div>						
					</div>

					<div class="notification is-danger" v-if="errors.length">
						<p v-for="error in errors" v-bind:key="error">{{ error }}</p>						
					</div>

					<div class="field">
						<div class="control">
							<button class="button">Log in</button>
							<button class="btn_rp button" v-on:click="resetPassword += 1">Forgot Password</button> 						
						</div>											
					</div>					

					<hr>
					<!--
					Or <router-link to="/sign-up">click here</router-link> to sign up! 
					-->

				</form>				
			</div>			
		</div>		
	</div>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

export default {
	name: 'LogIn',
	data() {
		return {
			username: '',
			password: '',
			errors: [],
			resetPassword:0
		}
	},
	mounted() {
		document.title = 'Log In | Growe'
	},
	methods: {
		async submitForm() {
			axios.defaults.headers.common["Authorization"] = ""

			localStorage.removeItem("token")
			
			if (this.resetPassword > 0) {
				const formData = {
					email: this.username
				}

				await axios
						.post("/api/v1/users/reset_password/", formData)
						.then(response => {	
							toast({
								message: 'Check your email for password reset link',
								type: 'is-success',
								dismissible: true,
								pauseOnHover: true,
								duration: 2000,
								position: 'top',
							})
							console.log(response.data)
						})
						.catch(error => {
							if (error.response) {
								for (const property in error.response.data) {
									this.errors.push(`${property}: ${error.response.data[property]}`)
								}
							} else {
								toast({
									message: 'Check your email for password reset link',
									type: 'is-success',
									dismissible: true,
									pauseOnHover: true,
									duration: 2000,
									position: 'top',
								})
								console.log(JSON.stringify(error))
							}
						})						
			} else {
				const formData = {
					email: this.username,
					password: this.password
				}												

				await axios
						.post("/api/v1/token/login", formData)
						.then(response => {
							const token = response.data.auth_token

							this.$store.commit('setToken', token)

							axios.defaults.headers.common['Authorization'] = "Token " + token						

							localStorage.setItem("token", token)
							localStorage.setItem('username',formData.email)

							const toPath = this.$route.query.to || '/my-account'

							this.$router.push(toPath)
						})
						.catch(error => {
							if (error.response) {
								for (const property in error.response.data) {
									this.errors.push(`${property}: ${error.response.data[property]}`)
								}
							} else {
								this.errors.push('Something went wrong. Please try again')

								console.log(JSON.stringify(error))
							}
						})
			}
		},
	}
}
</script>

<style lang="scss">
.btn_rp{
	margin-left: 10px;
}
</style>
