<template>
    <div>
        <vue-good-table
            :columns="columns"
            :rows="rows"
            :pagination-options="{
                enabled:true,
                mode: 'records',
                perPage: 20
            }"
            styleClass="vgt-table condensed bordered"            
            >
        </vue-good-table>        
    </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';


export default {
    name: 'EventsSummary',
    components: {
        VueGoodTable,
    },
    props: {
        events: [],
    },
    data() {
        return {
            columns: [
                {
                    label:'Stock',
                    field: 'stockname',
                },
                {
                    label:'Event',
                    field: 'subject',
                },
                {
                    label:'Ex-date',
                    field: 'exdate',
                },
                {
                    label:'Record Date',
                    field: 'recdate',
                },
            ],
            rows: this.events,
        }
    },    
    methods: {
    }
 }
</script>
